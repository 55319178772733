<div class="row p-3" style="background-color: #fafafa">
  <div class="col-2">
    <button
      kendoButton
      fillMode="solid"
      (click)="backToCatalog()"
      icon="arrow-chevron-left"
    >
      Catalog
    </button>
  </div>
  <div class="col-5">
      <span class="metadata-header">{{headerTitle}}</span>
      <span class="metadata-subheader">{{headerSubtitle}}</span>
  </div>
  <div class="col-5">
    <div class="row">
      <div class="col-12">
        <kendo-buttongroup>
          <button
            *ngIf="!readonly"
            kendoButton
            [toggleable]="false"
            fillMode="solid"
            (click)="onSave()"
            [disabled]="!isLoaded"
            icon="save"
          >
            Save
          </button>
          <button
            *ngIf="!readonly"
            kendoButton
            [toggleable]="false"
            fillMode="solid"
            (click)="toggleImport()"
            [disabled]="!isLoaded"
            icon="import"
            style="margin-left: 10px"
          >
            Import Esri XML
          </button>
          <button
            kendoButton
            [toggleable]="false"
            fillMode="solid"
            (click)="onExportEsri()"
            [disabled]="!isLoaded"
            icon="export"
            style="margin-left: 10px"
          >
            Export Esri
          </button>
          <button
            kendoButton
            [toggleable]="false"
            fillMode="solid"
            (click)="onExportIso()"
            [disabled]="!isLoaded"
            icon="export"
            style="margin-left: 10px"
          >
            Export ISO19115
          </button>
        </kendo-buttongroup>
      </div>
      <div *ngIf="statusMessage && statusMessage.length" class="col-12" style="padding-top: 10px">
        {{statusMessage}}
      </div>
      <div class="col-12">
        <div *ngIf="importToggle" style="border: 1px solid #ccc">
          <kendo-fileselect
            [showFileList]="true"
            (select)="selectFile($event);checkAllowImport($event)"
            [restrictions]="fileRestrictions"
            [multiple]="false"
            (remove)="enableFileSelect = false"

          >
          </kendo-fileselect>
          <button
            (click)="importFile()"
            kendoButton
            style="margin: 10px"
            themeColor="primary"
            [disabled]="!enableFileSelect"
          >
            Import selected file
          </button>
          <button
            (click)="importToggle = false; enableFileSelect = false"
            kendoButton
            style="margin: 10px"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
