import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Metadata } from 'src/app/models/metadata';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isLoaded: boolean;
  @Input() backToCatalog: Function;
  @Input() onSave: Function;
  @Input() onImport: Function;
  @Input() onExportEsri: Function;
  @Input() onExportIso: Function;
  @Input() fileRestrictions: FileRestrictions;
  @Input() selectFile: Function;
  @Input() importFile: Function;
  @Input() setStatus: Function;
  @Input() xmlData: string;
  @Input() metadata: Metadata;
  @Input() readonly: boolean;
  @Input() showImportSelected: boolean;
  @Input() statusMessage: string;
  @Output() showImportSelectedChange: EventEmitter<boolean> = new EventEmitter();

  public importToggle: boolean = false;
  public importButtonToggle: boolean = true;
  public headerTitle: string = '';
  public headerSubtitle: string = '';
  public enableFileSelect: boolean = false;

  constructor() {}

  checkAllowImport = (e: any) => {
    if (e.files[0]) {this.enableFileSelect = e.files[0].extension.toUpperCase().includes('XML');}
  };

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['metadata'];
    if (change) {
      this.headerSubtitle = '';
      this.headerTitle = '';
      this.importToggle = false;
  
      if (!change.firstChange && change.currentValue) {
        let frmGroup = change.currentValue.formGroup;
        let citation = frmGroup.controls['citation'].value;
        let layerType = change.currentValue.layerType;
        if (citation) this.headerTitle = citation;
        if (layerType) this.headerSubtitle += ' (' + layerType + ')';
      }
    }
  }

  toggleImport() {
    this.importToggle = !this.importToggle;
    if (this.importToggle) {
      this.setStatus(null);
    }
  }
}
