import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { Globals } from "src/app/globals";
import { AppService } from "src/app/services/app.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Metadata } from "src/app/models/metadata";

@Injectable()
export class MetadataService {

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private appService: AppService,
    private authenticationService: AuthenticationService,
  ) {
  }

  public isXmlData(xml: string): boolean {
    return (xml && xml.startsWith("<?xml"));
  }

  public isEsriXmlMetadata(xml: string): boolean {
    return (xml && xml.includes("<metadata") && xml.includes("<Esri"));
  }

  public async getEsriMetadataXml(itemId: string, layerType: string, source: string): Promise<any> {
    const endpoint = `${environment.proxyUrl}GetEsriMetadataXml`;
    //const endpoint = "http://localhost:7071/api/GetEsriMetadataXml";

    const params = new HttpParams()
      .append("subscription-key", environment.proxyKey)
      .append("token", this.authenticationService.token)
      .append("layerType", layerType)
      .append("itemId", itemId)
      .append("source", source)
      ;

    return this.http
      .get<any>(endpoint, { params: params })
      .pipe(map(result => {
        return result;
      }),
        catchError(this.globals.handleError)
      )
      .toPromise()
      .catch((err) => {
        this.appService.setErrorMessage(`Error in GetEsriMetadataXml: ${err}`);
        return null;
      });
  }

  public async loadMetadataForItem(itemId: string, layerType: string, source: string): Promise<Metadata> {
    if (!this.authenticationService.isLoggedIn || !itemId || !itemId.length) {
      return null;
    }

    let metadata: Metadata = null;
    const result: any = await this.getEsriMetadataXml(itemId, layerType, source);

    if (result && result.status && result.status === "OK") {
      metadata = await this.loadMetadataFromXml(itemId, layerType, source, result.canEdit, result.xml);
    } else if (result) {
      this.appService.setErrorMessage(`${result.status}: ${result.message}`);
    } else {
      this.appService.setErrorMessage("The metadata could not be loaded.");
    }

    return metadata;
  }

  public async loadMetadataFromXml(itemId: string, layerType: string, source: string, canEdit: boolean, xml: string): Promise<Metadata> {
    return new Promise((resolve, reject) => {
      const metadata: Metadata = new Metadata(layerType, itemId, source, canEdit, xml);
      resolve(metadata);
    });
  }

  public async saveEsriMetadataXml(metadata: Metadata): Promise<boolean> {
    if (!metadata) {
      return null;
    }

    const date: Date = new Date();
    const time: string = `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
    metadata.formGroup.controls["metadataLastUpdatedDate"].setValue(date);
    metadata.formGroup.controls["metadataLastUpdatedTime"].setValue(time);
    metadata.formGroup.controls["metadataLastUpdatedBy"].setValue(this.authenticationService.username);

    const formData: FormData = new FormData()
    formData.append("token", this.authenticationService.token);
    formData.append("layerType", metadata.layerType);
    formData.append("itemId", metadata.itemId);
    formData.append("source", metadata.source);
    formData.append("xml", metadata.esriXml);

    const params = new HttpParams()
      .append("subscription-key", environment.proxyKey)
      ;

    const endpoint = `${environment.proxyUrl}SaveEsriMetadataXml`;
    //const endpoint = "http://localhost:7071/api/SaveEsriMetadataXml";

    return this.http
    .post<any>(endpoint, formData, { params: params })
    .pipe(map(result => {
      let success: boolean = false;
      if (result && result.status && result.status === "OK") {
        success = true;
        metadata.formGroup.markAsPristine();
      } else if (result) {
        this.appService.setErrorMessage(`${result.status}: ${result.message}`);
      } else {
        this.appService.setErrorMessage("The metadata could not be saved.");
      }
      return success;
    }),
      catchError(this.globals.handleError)
    )
    .toPromise()
    .catch((err) => {
      this.appService.setErrorMessage(`Error in SaveEsriMetadataXml: ${err}`);
      return false;
    });
  }

  public async setLastUpdate(itemId: string, layerType: string, source: string): Promise<boolean> {
    const date: Date = new Date();
    const lastUpdateDate: string = date.getFullYear().toString() + "-"
      + String(date.getMonth() + 1).padStart(2, "0") + "-"
      + String(date.getDate()).padStart(2, "0");

    const params = new HttpParams()
      .append("subscription-key", environment.proxyKey)
      .append("token", this.authenticationService.token)
      .append("layerType", layerType)
      .append("itemId", itemId)
      .append("source", source)
      .append("lastUpdatedDate", lastUpdateDate)
      .append("lastUpdatedBy", this.authenticationService.username)
      ;

    const endpoint = `${environment.proxyUrl}SetLastUpdate`;
    return this.http
    .put<any>(endpoint, null, { params: params })
    .pipe(map(result => {
      let success: boolean = false;
      if (result && result.status && result.status === "OK") {
        success = true;
      } else if (result) {
        this.appService.setErrorMessage(`${result.status}: ${result.message}`);
      } else {
        this.appService.setErrorMessage("The metadata could not be updated.");
      }
      return success;
    }),
      catchError(this.globals.handleError)
    )
    .toPromise()
    .catch((err) => {
      this.appService.setErrorMessage(`Error in SetLastUpdate: ${err}`);
      return false;
    });
  }

}
