<app-header
  [isLoaded]="isLoaded"
  [backToCatalog]="backToCatalog"
  [onSave]="onSave"
  [onExportEsri]="onExportEsri"
  [onExportIso]="onExportIso"
  [fileRestrictions]="fileRestrictions"
  [selectFile]="selectFile"
  [importFile]="importFile"
  [xmlData]="xmlData"
  [metadata]="metadata"
  [readonly]="!(metadata && metadata.canEdit)"
  [showImportSelected]="showImportSelected"
  [metadata]="metadata"
  [statusMessage]="statusMessage"
  [setStatus]="setStatus"
></app-header>
<app-content
  [metadata]="metadata"
  [isReady]="isReady"
  [appService]="appService"
></app-content>
