import { Component,  OnInit } from "@angular/core";
import { GridOptions, GridReadyEvent, GridApi, SelectionChangedEvent } from "@ag-grid-community/core";
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CatalogService } from "src/app/services/catalog.service";
import { MetadataService } from "src/app/services/metadata.service";
import { CatalogItem } from "src/app/models/catalog-item";
import { DistinctValuesFilterComponent } from "src/app/components/distinct-values-filter/distinct-values-filter.component";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {

  public year: number = new Date().getFullYear();
  public errorMessage: string = null;
  public statusMessage: string = null;
  public isLoading: boolean = false;
  public gridOptions: GridOptions;
  public gridApi: GridApi;

  public get showError(): boolean {
    return this.errorMessage && this.errorMessage.length > 0;
  }

  public get showReload(): boolean {
    return this.authenticationService.isLoggedIn && !this.isLoading;
  }

  constructor(
    private appService: AppService,
    private loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    public catalogService: CatalogService,
    public metadataService: MetadataService,
  ) {}

  ngOnInit(): void {
    this.initGrid();

    this.appService.loadCatalogObservable$.subscribe((reload: boolean) => {
      this.loadCatalog(reload);
    });

    this.appService.showCatalogObservable$.subscribe(() => {
      this.loadCatalog(false);
    });

    this.appService.exportCatalogObservable$.subscribe(() => {
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv({
          fileName: "catalog.csv",
        });
      }
    });

    this.authenticationService.initialize()
    .then((init) => {
      if (!(init && init.length)) {
        this.authenticationService.checkSignin(false).then((signin) => {
          if (signin && signin.length) {
            this.appService.setErrorMessage(signin); 
          }
        });
      } else {
        this.appService.setErrorMessage(init);
      }
    });
  }

  loadCatalog(reload: boolean): void {
    if (this.appService.showCatalog) {
      this.isLoading = true;
      this.appService.setStatusMessage(null);

      this.loaderService.loading.next(true);
      this.loaderService.progress.next(0);
  
      this.catalogService
        .loadCatalog(reload, this.progressCallback)
        .then((catalog) => {
          if (catalog) {
            this.appService.setStatusMessage(`${catalog.length.toLocaleString('en-US')} records`);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.loaderService.progress.next(null);
          this.loaderService.loading.next(false);
        });
    }
  }

  progressCallback = (percentComplete: number) => {
    this.loaderService.progress.next(percentComplete);
  }

  initGrid(): void {
    this.gridOptions = {
      
      rowSelection: 'single',
      suppressCellFocus: true,
      context: {
        parentComponent: this
      },
      components: {
        distinctValuesFilter: DistinctValuesFilterComponent,
      },
      defaultColDef: {
        headerClass: 'metadata-header',
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
    };

    this.gridOptions.columnDefs = [
      {
        field: "category",
        headerName: "Category",
        checkboxSelection: true,
        filter: "distinctValuesFilter",
        filterParams: {
          field: "category",
        },
        cellRenderer: ((params: any) => {
          return params.value && params.value.length ? params.value : "<span>&nbsp;</span>";
        }),
      },
      {
        field: "title",
        headerName: "Title",
      },
      {
        field: "type",
        headerName: "Type",
        filter: "distinctValuesFilter",
        filterParams: {
          field: "type",
        },
      },
      {
        field: "owner",
        headerName: "Owner",
        filter: "distinctValuesFilter",
        filterParams: {
          field: "owner",
        },
      },
      {
        field: "description",
        headerName: "Description",
        cellRenderer: ((params: any) => {
          const div: HTMLDivElement = document.createElement("div");
          div.innerHTML = params.value;
          return div.textContent || div.innerText || "";
        }),
      },
      {
        field: "snippet",
        headerName: "Snippet",
      },
      {
        field: "access",
        headerName: "Access",
        filter: "distinctValuesFilter",
        filterParams: {
          field: "access",
        },
      },
      {
        field: "id",
        headerName: "Item ID",
      },
      {
        field: "source",
        headerName: "Source",
      },
    ];
  }

  onGridReady(evt: GridReadyEvent): void {
    this.gridApi = evt.api;
    this.gridApi.sizeColumnsToFit();
  }

  onSelectionChanged(evt: SelectionChangedEvent): void {
    const rows: any[] = evt.api.getSelectedRows();
    if (rows && rows.length) {
      const item: CatalogItem = rows[0];

      this.appService.id = item.id ? item.id : "";
      this.appService.type = item.type ? item.type : "";
      this.appService.source = item.source ? item.source : "";

      this.appService.toggleView("details");
      evt.api.deselectAll();
    }
  }

  onFilterChanged(evt: any): void {
    this.updateStatus();
  }

  updateStatus(): void {
    if (this.gridApi) {
      let count: number = null;

      if (this.authenticationService.isLoggedIn) {
        count = this.gridApi.getDisplayedRowCount();
      }

      if (count == null) {
        count = 0;
      }

      this.appService.setStatusMessage(`${count} records`);
    }
  }

}
